import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/common/Layout";
import Nav from "../components/common/Nav";
import "../styles/job-styles.css";

const JobPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark;
  const { previous, next } = data;

  return (
    <Layout location={location} pageTitle={post.frontmatter.title}>
      <div className="job-wrapper" style={{ fontFamily: "Lato" }}>
        <article
          className="job-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h1 style={{ fontFamily: "Lato" }} itemProp="headline">
              {post.frontmatter.title}
            </h1>
          </header>
          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
          <hr />
        </article>
      </div>
    </Layout>
  );
};

export default JobPostTemplate;

export const query = graphql`
  query JobPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt
      html
      frontmatter {
        title
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
